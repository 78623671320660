import { isBefore } from 'date-fns'
import {
  ConstructionProject,
  ConstructionProjectApiResponse,
  ConstructionProjectCreatePayload,
  ProjectType,
  ProjectStatus
} from '@homevest/types/construction-projects'
import { ConstructionScope } from '@homevest/types/construction-scopes'
import {
  REQUIRED_FIELDS,
  PROJECT_TYPES,
  PROJECT_STATUSES
} from '@homevest/utils/construction-projects'
import { useQuery } from '@tanstack/react-query'

import axios from 'lib/axios'
import { formatUTCDate } from 'lib/date-time'
import { startCase } from 'lodash'

type ValidationResult = {
  isValid: boolean
  errors: Record<keyof ConstructionProjectCreatePayload, string>
}

export const createConstructionProject = async (payload: ConstructionProjectCreatePayload) => {
  const response = await axios.post<ConstructionProject>('/admin/construction_projects', payload)
  return response.data
}

export const validateConstructionProject = (
  payload: ConstructionProjectCreatePayload
): ValidationResult => {
  const errors = {} as Record<string, string>

  REQUIRED_FIELDS.forEach((field) => {
    if (!payload[field]) {
      errors[field] = 'This field is required!'
    }
  })

  if (payload.start_date && payload.end_date) {
    const startDate = new Date(payload.start_date)
    const endDate = new Date(payload.end_date)

    if (isBefore(endDate, startDate)) {
      errors.end_date = 'End date must be after start date!'
    }
  }

  if (payload.estimated_cost && payload.estimated_cost < 0) {
    errors.estimated_cost = 'Estimated cost must be a positive number!'
  }

  if (payload.actual_cost && payload.actual_cost < 0) {
    errors.actual_cost = 'Actual cost must be a positive number!'
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  }
}

export const formatProjectTitle = (project?: ConstructionProjectApiResponse): string => {
  if (!project) {
    return ''
  }

  let projectName = project.type.toUpperCase()
  if (project.address) {
    projectName += ` - ${project.address}`
  }
  if (project.start_date) {
    projectName += ` - ${formatUTCDate(project.start_date)}`
  }

  return projectName
}

export const mapProjectTypeToLabel = (type: string): string => {
  switch (type) {
    case PROJECT_TYPES.TURN:
      return 'Turn'
    case PROJECT_TYPES.TURN_OI:
      return 'Turn - OI'
    case PROJECT_TYPES.DISPOSITION:
      return 'Disposition'
    case PROJECT_TYPES.DISPOSITON_INSPECTION:
      return 'Disposition - Inspection'
    case PROJECT_TYPES.REHAB:
      return 'Rehab'
    default:
      return startCase(type)
  }
}

type ProjectCountsBreakdown = Record<ProjectType | 'all', Record<ProjectStatus | 'all', number>>

export const getProjectCountsBreakdown = (
  projects?: ConstructionProjectApiResponse[]
): ProjectCountsBreakdown => {
  // Initialize all counters
  const countsByStatus = ['all', ...Object.values(PROJECT_STATUSES)].reduce((acc, status) => {
    acc[status as ProjectStatus | 'all'] = 0
    return acc
  }, {} as Record<ProjectStatus | 'all', number>)

  const countsBreakdown = ['all', ...Object.values(PROJECT_TYPES)].reduce((acc, type) => {
    acc[type as ProjectType | 'all'] = { ...countsByStatus }
    return acc
  }, {} as ProjectCountsBreakdown)

  if (!projects) {
    return countsBreakdown
  }

  projects.forEach((project) => {
    // update rollup
    countsBreakdown['all']['all']++
    countsBreakdown['all'][project.status as ProjectStatus]++

    // update for project type
    countsBreakdown[project.type as ProjectType]['all']++
    countsBreakdown[project.type as ProjectType][project.status as ProjectStatus]++
  })

  return countsBreakdown
}

export const useConstructionProjectScopeQuery = (constructionProjectId: string) =>
  useQuery<ConstructionScope | null, Error>({
    queryKey: ['getScope'],
    queryFn: async () => {
      const { data } = await axios.get<ConstructionScope | null>(
        `admin/construction_projects/${constructionProjectId}/scope`
      )
      return data
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false
  })
