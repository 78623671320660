import React from 'react'
import { Button, Heading, Link, Stack } from '@chakra-ui/react'
import type { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'
import { LATCHEL_STATUSES } from '@homevest/utils/latchel'

import ProjectNotes from 'components/Construction/Projects/ProjectNotes'
import ProjectDocuments from 'components/Construction/Projects/ProjectDocuments'
import ScopeLineItemsGrid from 'components/Construction/Projects/ScopeLineItemsGrid'
import { ContentSectionCard } from 'components/TailwindUIToolkit'
import { TICKETS_GRID_COLUMN_IDS } from 'components/Construction/Tickets/configuration'
import TicketsGrid from 'components/Construction/Tickets/TicketsGrid'
import { RETOOL_SCOPE_MANAGER_APP_URL } from 'constants/urls'
import { useConstructionProjectScopeQuery } from 'lib/construction-projects'

const ScopeLineItemsCard: React.FC<{
  constructionProjectId: string
}> = ({ constructionProjectId }) => {
  const queryResult = useConstructionProjectScopeQuery(constructionProjectId)
  const scope = queryResult.data
  const retoolUrl = scope
    ? `${RETOOL_SCOPE_MANAGER_APP_URL}/edit_scope?construction_scope_id=${scope.id}`
    : RETOOL_SCOPE_MANAGER_APP_URL

  return (
    <ContentSectionCard
      title={
        <Heading as='h4' size='md' my={1}>
          Scope Line Items
        </Heading>
      }
      action={
        <Button as={Link} isExternal href={retoolUrl} size='sm' variant='outline'>
          {scope ? 'Edit Scope in Retool' : 'Open Scope Manager Retool'}
        </Button>
      }
    >
      <ScopeLineItemsGrid data={queryResult} />
    </ContentSectionCard>
  )
}

const TicketsCard: React.FC<{ constructionProjectId: string }> = ({ constructionProjectId }) => (
  <ContentSectionCard
    title={
      <Heading as='h4' size='md' my={1}>
        Related Tickets
      </Heading>
    }
  >
    <TicketsGrid
      gridHeight={60}
      getTicketsConfig={{
        params: {
          project_id: constructionProjectId,
          statuses: Object.values(LATCHEL_STATUSES)
        }
      }}
      orderedColumnIds={[
        TICKETS_GRID_COLUMN_IDS.TITLE,
        TICKETS_GRID_COLUMN_IDS.CATEGORY,
        TICKETS_GRID_COLUMN_IDS.STATUS,
        TICKETS_GRID_COLUMN_IDS.VENDOR,
        TICKETS_GRID_COLUMN_IDS.START_DATE,
        TICKETS_GRID_COLUMN_IDS.END_DATE,
        TICKETS_GRID_COLUMN_IDS.COST,
        TICKETS_GRID_COLUMN_IDS.ACTIONS
      ]}
    />
  </ContentSectionCard>
)

const Content: React.FC<{ project: ConstructionProjectApiResponse }> = ({ project }) => (
  <Stack>
    <ScopeLineItemsCard constructionProjectId={project.id} />
    <TicketsCard constructionProjectId={project.id} />
    <div className='flex flex-col gap-3 2xl:flex-row'>
      <ProjectNotes constructionProjectId={project.id} />
      <ProjectDocuments constructionProjectId={project.id} />
    </div>
  </Stack>
)

export default Content
