import React from 'react'
import startCase from 'lodash/startCase'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { Link } from '@chakra-ui/react'
import { ConstructionProjectApiResponse } from '@homevest/types/construction-projects'

import { formatMoney } from 'lib/numbers'
import { formatUTCDate, formatDateWithDistance } from 'lib/date-time'
import { formatProjectTitle } from 'lib/construction-projects'
import SetFilter from 'components/AgGrid/filters/SetFilter'
import { mapContentToBadge } from 'components/TailwindUIToolkit/badges'
import { LINK_COLOR } from 'constants/colors'

const PROJECT: ColDef<ConstructionProjectApiResponse> = {
  colId: 'project',
  headerName: 'Project',
  resizable: true,
  valueGetter: (params) => formatProjectTitle(params?.data),
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse>) => {
    const { data, value } = params
    return (
      <Link isExternal color={LINK_COLOR} href={`/construction/projects/${data?.id}`}>
        {value}
      </Link>
    )
  },
  flex: 2
}

const ADDRESS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'address',
  headerName: 'Address',
  field: 'address',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse>) => {
    const { data } = params
    return (
      <Link isExternal color={LINK_COLOR} href={`/home/${data?.portfolio_home_id}`}>
        {data?.address ? `${data.address}, ${data.city}, ${data.state}, ${data?.zip}` : ''}
      </Link>
    )
  },
  valueFormatter: (params) => startCase(params.value),
  flex: 2
}

const MARKET: ColDef<ConstructionProjectApiResponse> = {
  colId: 'market',
  headerName: 'Market',
  field: 'market',
  resizable: true,
  valueFormatter: (params) => startCase(params.value),
  filter: SetFilter,
  flex: 1
}

const TYPE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'type',
  headerName: 'Type',
  field: 'type',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  flex: 1
}

const STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'status',
  headerName: 'Status',
  field: 'status',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  flex: 1
}

const LAST_STATUS_CHANGE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'last_status_change_at',
  headerName: 'Last Status Change',
  field: 'last_status_change_at',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatDateWithDistance(params.value, 'MMM do, yyyy') : '-'
  },
  wrapText: true,
  flex: 1
}

const SUBSTATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'substatus',
  headerName: 'Substatus',
  field: 'substatus',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  flex: 1
}

const TRACKING_STATUS: ColDef<ConstructionProjectApiResponse> = {
  colId: 'tracking_status',
  headerName: 'On Track?',
  field: 'tracking_status',
  resizable: true,
  cellRenderer: (params: ICellRendererParams<ConstructionProjectApiResponse, string>) => {
    return mapContentToBadge(params.value, { size: 'xs' })
  },
  filter: SetFilter,
  flex: 1
}

const START_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'start_date',
  headerName: 'Start Date',
  field: 'start_date',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const END_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'end_date',
  headerName: 'End Date',
  field: 'end_date',
  resizable: true,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const MOVE_OUT_DATE: ColDef<ConstructionProjectApiResponse> = {
  colId: 'move_out_date',
  headerName: 'Move Out Date',
  field: 'prior_rental.move_out_date',
  resizable: true,
  sort: 'asc',
  sortIndex: 0,
  valueFormatter: (params) => {
    return params.value ? formatUTCDate(params.value) : ''
  },
  flex: 1
}

const ESTIMATED_COST: ColDef<ConstructionProjectApiResponse> = {
  colId: 'estimated_cost',
  headerName: 'Est Cost ',
  field: 'estimated_cost',
  resizable: true,
  valueFormatter: (params) => formatMoney(parseFloat(params.value), 2, '$'),
  flex: 1
}

export const BASE_COLUMNS = [
  PROJECT,
  ADDRESS,
  MARKET,
  TYPE,
  STATUS,
  LAST_STATUS_CHANGE,
  SUBSTATUS,
  TRACKING_STATUS,
  START_DATE,
  END_DATE,
  MOVE_OUT_DATE,
  ESTIMATED_COST
]
