export const HUDSON_BASE = '/'
export const INTERNAL_PROPERTY_PAGE_SLUG = `${HUDSON_BASE}review/initial/` // append firestore id
export const RENTLY_PROPERTY_PAGE_SLUG = `https://secure.rently.com/properties/schedule_popup?uid=29445&name=` // add firestore id here
export const ZILLOW_PROPERTY_PAGE_SLUG = `https://www.zillow.com/homes/`
export const REMATCHING_TOOL_PAGE_SLUG = `https://upandup.tryretool.com/apps/6899f83e-dc3d-11ec-b888-b74d10445f94/Leasing/Home%20Matching%20Tool?_releaseVersion=latest#mls_listing_id=` // append mls listing id
export const INTERNAL_APPLICATION_SLUG = `${HUDSON_BASE}applications/` // append rental app id
export const RETOOL_APP_URL = `https://upandup.tryretool.com/apps/`
export const RETOOL_BID_TRACKING_APP_ID = `0634c540-d2b4-11eb-9b4a-6f90dcccc90f`
export const RETOOL_WAREHOUSE_TRANSFER_APP_ID = `3c362a3a-73e0-11ec-8502-6720aa058e55`
export const RETOOL_VACANT_SERVICING_APP_ID = `9d272d32-b11e-11ec-9ed7-abeff6a7c4ee`
export const RETOOL_WORK_ORDERS_APP_ID = `e680baa0-c0de-11ec-9c41-27e3ce2d3425`
export const RETOOL_SCOPE_MANAGER_APP_URL = `https://upandup.tryretool.com/app/scope_manager`
