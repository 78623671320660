import React from 'react'
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody
} from '@chakra-ui/react'

type SidepeekPropTypes = {
  isOpen: boolean
  onClose: () => void
  title?: React.ReactNode
}

const Sidepeek: React.FC<React.PropsWithChildren<SidepeekPropTypes>> = ({
  isOpen,
  onClose,
  title,
  children
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size='lg'>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{title}</DrawerHeader>
        <DrawerBody>{children}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default Sidepeek
