import React, { useState } from 'react'
import { omit, pick, startCase } from 'lodash'
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useToast
} from '@chakra-ui/react'
import type {
  ConstructionProjectApiResponse,
  ConstructionProjectUpdatePayload
} from '@homevest/types/construction-projects'
import {
  PROJECT_CANCELATION_REASONS,
  PROJECT_STATUSES,
  PROJECT_SUBSTATUSES,
  PROJECT_TRACKING_STATUSES,
  PROJECT_TYPES
} from '@homevest/utils/construction-projects'
import { CAPABILITY_TYPES } from '@homevest/utils/capabilities'

import { ContentSectionCard } from 'components/TailwindUIToolkit'
import axios from 'lib/axios'
import { useUpupAdminsWithCapabilitiesByCapabilityTypeQuery } from 'graphql/generated'
import { formatDateWithDistance, formatUTCDate } from 'lib/date-time'
import {
  EditableDate,
  EditableDropdown,
  EditableNumber,
  errorToast,
  ImmutableText,
  successToast
} from './components'
import { mapProjectTypeToLabel } from 'lib/construction-projects'
import { Link } from 'ui'

type DisplayMetadata = {
  plannedStartDate: Date | string | null
  plannedEndDate: Date | string | null
}

const mapValuesToStartCase = (object: Record<string, string>) =>
  Object.fromEntries(Object.values(object).map((v) => [v, startCase(v)]))

const SidebarEditButton: React.FC<{
  isEditing: boolean
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  onCancel: () => void
  onSubmit: () => Promise<boolean>
}> = ({ isEditing, setIsEditing, onCancel, onSubmit }) => {
  const toggleEdit = () => setIsEditing((x) => !x)

  if (!isEditing) {
    return (
      <Button onClick={toggleEdit} colorScheme='teal' size='sm' variant='outline'>
        Edit
      </Button>
    )
  }

  return (
    <HStack>
      <Button
        onClick={() => {
          onCancel()
          toggleEdit()
        }}
        size='sm'
        variant='outline'
      >
        Cancel
      </Button>
      <Button
        onClick={async () => {
          const result = await onSubmit()
          if (result) {
            toggleEdit()
          }
        }}
        colorScheme='green'
        size='sm'
      >
        Submit
      </Button>
    </HStack>
  )
}

const SidebarFields: React.FC<{
  project: ConstructionProjectApiResponse
  isEditingSidebar: boolean
  updatePayload: ConstructionProjectUpdatePayload
  displayMetadata: DisplayMetadata
  setUpdatePayloadAttribute: (
    attribute: keyof ConstructionProjectUpdatePayload
  ) => (value?: string | number | Date) => void
}> = ({ project, isEditingSidebar, updatePayload, displayMetadata, setUpdatePayloadAttribute }) => {
  const [{ data }] = useUpupAdminsWithCapabilitiesByCapabilityTypeQuery({
    variables: {
      capability_types: [
        CAPABILITY_TYPES.CONSTRUCTION_PROJECT_ASSIGNEE,
        CAPABILITY_TYPES.CONSTRUCTION_PROJECT_MANAGER
      ]
    }
  })

  const assignableAdmins: Record<string, string> = data
    ? Object.fromEntries(data.admins.map((a) => [a.id, `${a.first_name} ${a.last_name}`]))
    : {}

  const formatDateWithPlannedDate = (current: Date | undefined, planned: Date | string | null) => {
    if (!planned) {
      return current ? formatUTCDate(current, 'MMM do, yyyy') : ''
    }

    return (
      <>
        <Text>{formatUTCDate(current, 'MMM do, yyyy')}</Text>
        <Text as={'i'} fontSize={'small'}>
          (Planned: {formatUTCDate(planned, 'MMM do')})
        </Text>
      </>
    )
  }

  return (
    <Stack>
      <EditableDropdown
        title='Type'
        isEditing={isEditingSidebar}
        editOptions={mapValuesToStartCase(PROJECT_TYPES)}
        value={updatePayload.type}
        setValue={setUpdatePayloadAttribute('type')}
        isRequired={true}
        valueFormatter={mapProjectTypeToLabel}
      />
      <EditableDropdown
        title='Status'
        isEditing={isEditingSidebar}
        editOptions={mapValuesToStartCase(omit(PROJECT_STATUSES, ['CANCELED']))}
        value={updatePayload.status}
        setValue={setUpdatePayloadAttribute('status')}
        isRequired={true}
        isDisabled={!!project.canceled_at}
      />
      <ImmutableText
        title='Last Status Change'
        value={
          project.last_status_change_at
            ? formatDateWithDistance(project.last_status_change_at, 'MMM do, yyyy')
            : '-'
        }
      />
      <EditableDropdown
        title='Substatus'
        isEditing={isEditingSidebar}
        editOptions={mapValuesToStartCase(PROJECT_SUBSTATUSES)}
        value={updatePayload.substatus ?? undefined}
        setValue={setUpdatePayloadAttribute('substatus')}
        isRequired={true}
      />
      <EditableDropdown
        title='Tracking Status'
        isEditing={isEditingSidebar}
        editOptions={mapValuesToStartCase(PROJECT_TRACKING_STATUSES)}
        value={updatePayload.tracking_status}
        setValue={setUpdatePayloadAttribute('tracking_status')}
        isRequired={true}
      />
      <Divider my={2} />
      <EditableDate
        title='Start Date'
        isEditing={isEditingSidebar}
        value={updatePayload.start_date ? new Date(updatePayload.start_date) : undefined}
        setValue={setUpdatePayloadAttribute('start_date')}
        displayFormatter={(value) =>
          formatDateWithPlannedDate(value, displayMetadata.plannedStartDate)
        }
      />
      <EditableDate
        title='End Date'
        isEditing={isEditingSidebar}
        value={updatePayload.end_date ? new Date(updatePayload.end_date) : undefined}
        setValue={setUpdatePayloadAttribute('end_date')}
        displayFormatter={(value) =>
          formatDateWithPlannedDate(value, displayMetadata.plannedEndDate)
        }
      />
      <EditableNumber
        title='Estimated Cost'
        isEditing={isEditingSidebar}
        value={updatePayload.estimated_cost ?? undefined}
        setValue={setUpdatePayloadAttribute('estimated_cost')}
      />
      <EditableNumber
        title='Actual Cost'
        isEditing={isEditingSidebar}
        value={updatePayload.actual_cost ?? undefined}
        setValue={setUpdatePayloadAttribute('actual_cost')}
      />
      <EditableDropdown
        title='Assigned Admin'
        isEditing={isEditingSidebar}
        editOptions={assignableAdmins}
        value={updatePayload.assigned_to_admin_id ?? undefined}
        setValue={setUpdatePayloadAttribute('assigned_to_admin_id')}
        valueFormatter={(val) => assignableAdmins[val]}
      />
      <Divider my={2} />
      <HStack>
        <Text flex={1}>Prior Rental:</Text>
        <Box flex={1}>
          {project.prior_rental?.id ? (
            <Link useRouter isExternal showIcon to={`/rent-roll/${project.prior_rental?.id}`}>
              Rent Roll
            </Link>
          ) : (
            '-'
          )}
        </Box>
      </HStack>
      <ImmutableText
        title='Move Out Date'
        value={
          project.prior_rental?.move_out_date
            ? formatUTCDate(project.prior_rental?.move_out_date, 'MMM do, yyyy')
            : '-'
        }
      />
      <Divider my={2} />
      <ImmutableText
        title='Tour Ready Date'
        value={formatUTCDate(project.tour_ready_date, 'MMM do, yyyy')}
      />
      <ImmutableText
        title='Rent Ready Date'
        value={formatUTCDate(project.rent_ready_date, 'MMM do, yyyy')}
      />
    </Stack>
  )
}

const CancelationReasonDropdown: React.FC<{
  cancelationReason: string
  setCancelationReason: React.Dispatch<React.SetStateAction<string>>
}> = ({ cancelationReason, setCancelationReason }) => (
  <FormControl isRequired>
    <FormLabel>Reason for Cancelation</FormLabel>
    <Select
      size='sm'
      value={cancelationReason}
      onChange={(e) => setCancelationReason(e.target.value)}
    >
      <option disabled key='' value=''>
        Select a cancelation reason
      </option>
      {Object.entries(mapValuesToStartCase(PROJECT_CANCELATION_REASONS)).map(
        ([value, displayValue]) => (
          <option key={value} value={value}>
            {displayValue}
          </option>
        )
      )}
    </Select>
  </FormControl>
)

const CancelProjectModal: React.FC<{
  project: ConstructionProjectApiResponse
  refetch: () => Promise<void>
  isOpen: boolean
  closeModal: () => void
}> = ({ project, refetch, isOpen, closeModal }) => {
  const [cancelationReason, setCancelationReason] = useState('')
  const toast = useToast()
  const successToastId = 'success-toast'
  const errorToastId = 'error-toast'

  const onClose = () => {
    closeModal()
    setCancelationReason('')
  }

  const cancelProject = async () => {
    try {
      await axios.post(`admin/construction_projects/${project.id}/cancel`, {
        cancelation_reason: cancelationReason
      })
      if (!toast.isActive(successToastId)) {
        successToast(toast, {
          id: successToastId,
          description: `Canceled construction project!`
        })
      }
      return true
    } catch (err: any) {
      if (!toast.isActive(errorToastId)) {
        errorToast(toast, {
          id: errorToastId,
          description: `An error occured while canceling the construction project: ${err.message}`
        })
      }
      return false
    }
  }

  const uncancelProject = async () => {
    try {
      await axios.post(`admin/construction_projects/${project.id}/uncancel`)
      if (!toast.isActive(successToastId)) {
        successToast(toast, {
          id: successToastId,
          description: `Un-canceled construction project! Please change the project status if needed.`
        })
      }
      return true
    } catch (err: any) {
      if (!toast.isActive(errorToastId)) {
        errorToast(toast, {
          id: errorToastId,
          description: `An error occured while un-canceling the construction project: ${err.message}`
        })
      }
      return false
    }
  }

  const isCanceled = !!project.canceled_at

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader>{isCanceled ? 'Undo Project Cancelation' : 'Cancel Project'}</ModalHeader>
        <ModalBody>
          {isCanceled ? (
            <div>
              This will put the project in status <span className='font-semibold'>complete</span>.
              You can edit the project status afterwards.
            </div>
          ) : (
            <CancelationReasonDropdown
              cancelationReason={cancelationReason}
              setCancelationReason={setCancelationReason}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={async () => {
              let result: boolean
              if (isCanceled) {
                result = await uncancelProject()
              } else {
                result = await cancelProject()
              }
              if (result) {
                onClose()
                await refetch()
              }
            }}
            isDisabled={!isCanceled && !cancelationReason}
            colorScheme='red'
          >
            {isCanceled ? 'Undo Cancel' : 'Cancel'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const ProjectDetailsPanel: React.FC<{
  project: ConstructionProjectApiResponse
  refetch: () => Promise<any>
}> = ({ project, refetch }) => {
  const defaultUpdatePayload = pick(project, [
    'actual_cost',
    'assigned_to_admin_id',
    'end_date',
    'estimated_cost',
    'start_date',
    'status',
    'substatus',
    'tracking_status',
    'type'
  ])
  const [isEditingSidebar, setIsEditingSidebar] = useState(false)
  const [updatePayload, setUpdatePayload] =
    useState<ConstructionProjectUpdatePayload>(defaultUpdatePayload)

  const [isCancelProjectModalOpen, setIsCancelProjectModalOpen] = useState(false)

  const toast = useToast()
  const successToastId = 'success-toast'
  const errorToastId = 'error-toast'

  const setUpdatePayloadAttribute =
    (attribute: keyof ConstructionProjectUpdatePayload) => (value?: string | number | Date) =>
      setUpdatePayload({ ...updatePayload, [attribute]: value })

  const submitUpdatePayload = async () => {
    try {
      await axios.put(`admin/construction_projects/${project.id}`, updatePayload)
      if (!toast.isActive(successToastId)) {
        successToast(toast, {
          id: successToastId,
          description: `Updated construction project!`
        })
      }
      await refetch()
      return true
    } catch (err: any) {
      if (!toast.isActive(errorToastId)) {
        errorToast(toast, {
          id: errorToastId,
          description: `An error occured while updating the construction project: ${err.message}`
        })
      }
      return false
    }
  }

  const toggleCancelProjectModalOpen = () => setIsCancelProjectModalOpen((x) => !x)

  return (
    <ContentSectionCard
      size='fit-content'
      title={
        <Heading as='h4' size='md'>
          Details
        </Heading>
      }
      action={
        <SidebarEditButton
          isEditing={isEditingSidebar}
          setIsEditing={setIsEditingSidebar}
          onCancel={() => setUpdatePayload(defaultUpdatePayload)}
          onSubmit={submitUpdatePayload}
        />
      }
      collapsable={false}
    >
      <Stack gap={4} pt={3} pb={5} px={5}>
        {!isEditingSidebar && (
          <Text as='i' fontWeight={'light'} fontSize={'small'}>
            Project created {formatUTCDate(project.created_at, 'MMM do, yyyy')}
            {project.canceled_at && (
              <div>Project canceled {formatUTCDate(project.canceled_at, 'MMM do, yyyy')}</div>
            )}
          </Text>
        )}
        <SidebarFields
          project={project}
          isEditingSidebar={isEditingSidebar}
          updatePayload={updatePayload}
          displayMetadata={{
            plannedStartDate: project.planned_start_date ?? null,
            plannedEndDate: project.planned_end_date ?? null
          }}
          setUpdatePayloadAttribute={setUpdatePayloadAttribute}
        />
        {isEditingSidebar && (
          <Button
            size='sm'
            colorScheme='red'
            variant='outline'
            onClick={toggleCancelProjectModalOpen}
          >
            {project.canceled_at ? 'Undo Cancelation' : 'Cancel Project'}
          </Button>
        )}
        <CancelProjectModal
          project={project}
          refetch={refetch}
          isOpen={isCancelProjectModalOpen}
          closeModal={toggleCancelProjectModalOpen}
        />
      </Stack>
    </ContentSectionCard>
  )
}

const Sidebar: React.FC<{
  project: ConstructionProjectApiResponse
  refetch: () => Promise<any>
}> = ({ project, refetch }) => <ProjectDetailsPanel project={project} refetch={refetch} />

export default Sidebar
