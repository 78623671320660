import React from 'react'
import * as SC from 'components/TailwindUIToolkit/StyledComponents'
import { assign, startCase } from 'lodash'
import {
  Home_Construction_Status_Enum_Enum,
  Home_Occupancy_Status_Enum_Enum,
  Home_Ownership_Status_Enum_Enum,
  Home_Summary_Status_Enum_Enum
} from 'graphql/generated'
import { rentals, utilities, verifications, tickets, constructionProjects } from '@homevest/utils'

import { UNDERWRITING_RECOMMENDATION_SUMMARIES } from 'lib/rental-application-underwriting'

const { RENTAL_PROGRAM_TYPES, RENTAL_PROGRAM_LEASE_TYPES } = rentals
const { UTILITIES_RESPONSIBLE_PARTIES } = utilities
const { VERIFICATION_STATUSES } = verifications
const { TICKET_CATEGORIES, TICKET_TYPES } = tickets
const { PROJECT_STATUSES, PROJECT_SUBSTATUSES, PROJECT_TRACKING_STATUSES, PROJECT_TYPES } =
  constructionProjects

type BadgeColor = 'Yellow' | 'Green' | 'Red' | 'Gray' | 'Blue' | 'Orange' | 'Purple'

// TOOD: these should be passed in by the caller so that we dont have collisions across statuses
export const mapStatusToBadgeColor: { [key: string]: BadgeColor } = {
  active: 'Green',
  // prequalification statuses
  needs_cosigner: 'Yellow',
  prequalified: 'Green',
  unqualified: 'Red',
  // funnel statuses
  applied: 'Blue',
  needs_standard_matching: 'Yellow',
  incomplete_tour: 'Yellow',
  needs_application_intro: 'Yellow',
  needs_tour_follow_up: 'Yellow',
  needs_priority_matching: 'Yellow',
  archived: 'Gray',
  // header statuses
  needs_prequalification: 'Yellow', // true if user does not have corresponding row in user_prequalification_data
  renter: 'Green', // true if user has active rental in rental_users table
  application_intro: 'Yellow', // might not be used
  canceled: 'Red',
  deleted: 'Red',
  // lead group user types (Gray if not listed)
  primary: 'Green',
  cosigner: 'Blue',
  // calls
  answeRed: 'Green',
  'no-answer': 'Red',
  'vm-answer': 'Yellow',
  'vm-left': 'Yellow',
  busy: 'Red',
  blocked: 'Red',
  error: 'Red',
  abandoned: 'Red',
  unknown: 'Gray',
  // rental app statuses
  created: 'Yellow',
  underwritten: 'Yellow',
  completed: 'Green',
  renewals: 'Orange',
  reviewed: 'Green',
  review_approved: 'Blue',
  review_unapproved: 'Red',
  complete: 'Green',
  pending_application_review: 'Yellow',
  pending_rental_agreement_creation: 'Yellow',
  pending_customer_information: 'Yellow',
  // documents
  pending_review: 'Yellow',
  valid: 'Green',
  invalid: 'Red',
  default: 'Green',
  pending: 'Yellow',
  resolved: 'Green',
  //note categories
  access: 'Green',
  home_purchase: 'Green',
  move_in: 'Green',
  move_out: 'Yellow',
  work_order: 'Yellow',
  project_notes: 'Blue',
  insurance: 'Blue',
  collections: 'Red',
  evictions: 'Red',
  utilities: 'Yellow',
  // occupancy
  [Home_Occupancy_Status_Enum_Enum.Standard]: 'Green',
  [Home_Occupancy_Status_Enum_Enum.Eviction]: 'Red',
  [Home_Occupancy_Status_Enum_Enum.VacantLeased]: 'Green',
  [Home_Occupancy_Status_Enum_Enum.Holdover]: 'Yellow',
  [Home_Occupancy_Status_Enum_Enum.NoticeLeased]: 'Green',
  [Home_Occupancy_Status_Enum_Enum.NoticeUnleased]: 'Yellow',
  [Home_Occupancy_Status_Enum_Enum.VacantUnleased]: 'Red',
  [Home_Occupancy_Status_Enum_Enum.Hold]: 'Yellow',
  // construction
  [Home_Construction_Status_Enum_Enum.None]: 'Gray',
  [Home_Construction_Status_Enum_Enum.AwaitingStart]: 'Gray',
  [Home_Construction_Status_Enum_Enum.InProgress]: 'Blue',
  // [Home_Construction_Status_Enum_Enum.PendingReview]: 'Yellow',
  [Home_Construction_Status_Enum_Enum.PendingWalkthrough]: 'Yellow',
  [Home_Construction_Status_Enum_Enum.RentReady]: 'Green',
  // [Home_Construction_Status_Enum_Enum.Canceled]: 'Red',
  [Home_Construction_Status_Enum_Enum.QualityCheck]: 'Blue',
  [Home_Construction_Status_Enum_Enum.TerminationRequested]: 'Yellow',
  // ownership
  [Home_Ownership_Status_Enum_Enum.NotOwned]: 'Gray',
  [Home_Ownership_Status_Enum_Enum.Bidding]: 'Blue',
  [Home_Ownership_Status_Enum_Enum.Screening]: 'Blue',
  [Home_Ownership_Status_Enum_Enum.Diligence]: 'Blue',
  [Home_Ownership_Status_Enum_Enum.Owned]: 'Green',
  [Home_Ownership_Status_Enum_Enum.Disposing]: 'Yellow',
  [Home_Ownership_Status_Enum_Enum.Sold]: 'Gray',
  [Home_Ownership_Status_Enum_Enum.Terminated]: 'Red',
  // summary
  // [Home_Summary_Status_Enum_Enum.NotOwned]: 'Gray',
  [Home_Summary_Status_Enum_Enum.Acquiring]: 'Blue',
  [Home_Summary_Status_Enum_Enum.AcquisitionTerminated]: 'Gray',
  [Home_Summary_Status_Enum_Enum.NotReadyLeased]: 'Yellow',
  [Home_Summary_Status_Enum_Enum.RentReadyLeased]: 'Green',
  [Home_Summary_Status_Enum_Enum.OccupiedEviction]: 'Yellow',
  [Home_Summary_Status_Enum_Enum.OccupiedHoldover]: 'Yellow',
  [Home_Summary_Status_Enum_Enum.OccupiedNotice]: 'Yellow',
  [Home_Summary_Status_Enum_Enum.OccupiedStandard]: 'Green',
  [Home_Summary_Status_Enum_Enum.RentReadyUnleased]: 'Yellow',
  [Home_Summary_Status_Enum_Enum.NotReadyUnleased]: 'Yellow',
  // [Home_Summary_Status_Enum_Enum.Sold]: 'Gray',
  // cant make it Red, duplicate unknown above
  // [Home_Summary_Status_Enum_Enum.Unknown]: 'Red',

  // Utility Responsibilities
  [UTILITIES_RESPONSIBLE_PARTIES.TENANT]: 'Green',
  [UTILITIES_RESPONSIBLE_PARTIES.UPANDUP]: 'Blue',

  // User Identity Verifications
  [VERIFICATION_STATUSES.VERIFIED]: 'Green',
  [VERIFICATION_STATUSES.PROCESSING]: 'Yellow',
  [VERIFICATION_STATUSES.SESSION_CREATED]: 'Yellow',
  [VERIFICATION_STATUSES.FAILED]: 'Red',

  // cashout statuses
  adjustments_finalized: 'Purple',
  utilities_finalized: 'Yellow',
  ledger_finalized: 'Orange',
  paid_out: 'Green',
  // down statuses
  down: 'Red',
  approved: 'Green',
  rejected: 'Red',
  deferred: 'Yellow',

  // User Income virtual statuses
  submitted: 'Yellow',
  additional_income_submitted: 'Yellow',
  additional_income_requested: 'Orange',
  not_submitted: 'Orange',

  // User Income Source statuses
  edit_requested: 'Orange',

  // Underwriting recommendation summaries
  [UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE]: 'Green',
  [UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_2X_DEPOSIT]: 'Orange',
  [UNDERWRITING_RECOMMENDATION_SUMMARIES.APPROVE_WITH_3X_DEPOSIT]: 'Orange',
  [UNDERWRITING_RECOMMENDATION_SUMMARIES.REJECT]: 'Red',
  [UNDERWRITING_RECOMMENDATION_SUMMARIES.MANUAL_REVIEW]: 'Yellow',

  // Vanilla vs. Up&Up lease config
  // [RENTAL_PROGRAM_TYPES.UPANDUP]: 'Blue',
  [RENTAL_PROGRAM_TYPES.VANILLA]: 'Yellow',
  [RENTAL_PROGRAM_LEASE_TYPES.upandup]: 'Blue',
  [RENTAL_PROGRAM_LEASE_TYPES.vanilla]: 'Yellow',

  // Ticket types
  [TICKET_TYPES.REHAB]: 'Red',
  [TICKET_TYPES.DISPOSITION]: 'Yellow',
  [TICKET_TYPES.TURN]: 'Blue',
  [TICKET_TYPES.MAINTENANCE]: 'Orange',

  // Construction project types
  // [PROJECT_TYPES.TURN]: 'Green',
  [PROJECT_TYPES.TURN_OI]: 'Blue',
  // [PROJECT_TYPES.DISPOSITION]: 'Purple',
  [PROJECT_TYPES.DISPOSITON_INSPECTION]: 'Yellow',
  // [PROJECT_TYPES.REHAB]: 'Blue',

  // Construction project statuses
  [PROJECT_STATUSES.PENDING_MOVE_OUT]: 'Gray',
  [PROJECT_STATUSES.PENDING_MOVE_OUT_INSPECTION]: 'Yellow',
  [PROJECT_STATUSES.PENDING_SCOPE]: 'Yellow',
  [PROJECT_STATUSES.PENDING_DISPATCH]: 'Yellow',
  [PROJECT_STATUSES.PENDING_CONSTRUCTION_START]: 'Blue',
  [PROJECT_STATUSES.PENDING_CONSTRUCTION_COMPLETION]: 'Blue',
  [PROJECT_STATUSES.PENDING_QUALITY_CONTROL]: 'Orange',
  [PROJECT_STATUSES.POST_QUALITY_CONTROL_TASKS]: 'Orange',
  // [PROJECT_STATUSES.COMPLETE]: 'Green',
  // [PROJECT_STATUSES.CANCELED]: 'Red',

  // Construction project tracking statuses
  [PROJECT_TRACKING_STATUSES.ON_TRACK]: 'Green',
  [PROJECT_TRACKING_STATUSES.AT_RISK]: 'Orange',
  [PROJECT_TRACKING_STATUSES.OFF_TRACK]: 'Red',
  [PROJECT_TRACKING_STATUSES.ESCALATED]: 'Purple',

  // Construction project substatuses
  [PROJECT_SUBSTATUSES.NOT_STARTED]: 'Gray',
  // [PROJECT_SUBSTATUSES.IN_PROGRESS]: 'Blue',
  // [PROJECT_SUBSTATUSES.BLOCKED]: 'Red',
  // [PROJECT_SUBSTATUSES.COMPLETE]: 'Green',

  // Ticket caetgories
  [TICKET_CATEGORIES.REKEY]: 'Orange',
  [TICKET_CATEGORIES.PAINTING]: 'Yellow',
  [TICKET_CATEGORIES.SECURITY_INSTALL]: 'Purple',
  [TICKET_CATEGORIES.MOVE_OUT_INSPECTION]: 'Orange',
  [TICKET_CATEGORIES.QC_INSPECTION]: 'Red',
  [TICKET_CATEGORIES.SNOW_REMOVAL]: 'Blue',
  [TICKET_CATEGORIES.LANDSCAPING]: 'Yellow',
  [TICKET_CATEGORIES.TREE_REMOVAL]: 'Green',
  [TICKET_CATEGORIES.ROOF]: 'Red',
  [TICKET_CATEGORIES.CLEANING]: 'Blue',
  [TICKET_CATEGORIES.HANDYMAN]: 'Orange',
  [TICKET_CATEGORIES.PLUMBING]: 'Blue',
  [TICKET_CATEGORIES.ELECTRICAL]: 'Yellow',
  [TICKET_CATEGORIES.TRASH_REMOVAL]: 'Purple',
  [TICKET_CATEGORIES.APPLIANCES]: 'Purple',
  [TICKET_CATEGORIES.COUNTERTOPS]: 'Red',
  [TICKET_CATEGORIES.HVAC]: 'Gray',
  [TICKET_CATEGORIES.PEST_CONTROL]: 'Orange',
  [TICKET_CATEGORIES.OCCUPANCY_INSPECTION]: 'Gray',
  [TICKET_CATEGORIES.MISC]: 'Gray'
}

export const mapContentToBadge = (
  status: string,
  optionOverrides: Partial<{
    colorOverride: BadgeColor
    dot: boolean
    fallbackText: string
    hoverText: string
    formatFn: (input: string) => string
    size: 'xs' | 'sm' | 'base' | 'lg' | 'xl'
    strikethrough: boolean
  }> = { dot: true, size: 'sm' }
) => {
  const options = assign({ dot: true, size: 'sm' }, optionOverrides)
  const badgeColor = options.colorOverride || mapStatusToBadgeColor[status] || 'Gray'
  const { dot, fallbackText, formatFn, hoverText, strikethrough, size } = options

  if (!status) {
    status = fallbackText || 'none'
  }
  if (formatFn) {
    status = formatFn(status)
  } else {
    status = startCase(status).replace('Upandup', 'Up&Up')
  }
  const badgeName = `${badgeColor}Badge` as const
  const dotName = `${badgeColor}Dot` as const
  const BadgeComponent = SC[badgeName]
  const DotComponent = SC[dotName]

  return (
    <BadgeComponent title={hoverText}>
      {dot ? <DotComponent /> : ' '}
      <SC.BadgeContent
        className={strikethrough ? 'line-through' : '' + size ? 'text-' + size : 'text-sm'}
        dot={dot}
      >
        {status}
      </SC.BadgeContent>
    </BadgeComponent>
  )
}
