import isBefore from 'date-fns/isBefore'
import { startCase } from 'lodash'
import { TICKET_TYPES } from '@homevest/utils/tickets'
import { DisplayTicket, Ticket } from '@homevest/types/tickets'

export type EnrichedTicketData = {
  project_id?: string | null
  category?: string | null
  type?: string | null
  actual_start_date?: Date | null
  estimated_end_date?: Date | null
  actual_end_date?: Date | null
  estimated_cost?: number | null
  actual_cost?: number | null
}

type ValidationResult = {
  isValid: boolean
  errors: Partial<Record<keyof EnrichedTicketData, string>>
}

export const convertDisplayTicketsToTickets = (displayTickets: DisplayTicket[]): Ticket[] =>
  displayTickets.map((dt) => ({
    ...dt,
    actual_cost: dt.actual_cost ? Number(dt.actual_cost) : null,
    actual_end_date: dt.actual_end_date ? new Date(dt.actual_end_date) : null,
    actual_start_date: dt.actual_start_date ? new Date(dt.actual_start_date) : null,
    current_start_date: dt.current_start_date ? new Date(dt.current_start_date) : null,
    estimated_cost: dt.estimated_cost ? Number(dt.estimated_cost) : null,
    estimated_end_date: dt.estimated_end_date ? new Date(dt.estimated_end_date) : null,
    max_cost: dt.max_cost ? Number(dt.max_cost) : null,
    planned_start_date: dt.planned_start_date ? new Date(dt.planned_start_date) : null
  }))

export const validateEnrichTicketPayload = (payload: EnrichedTicketData): ValidationResult => {
  const errors: Partial<Record<keyof EnrichedTicketData, string>> = {}
  const REQUIRED_FIELD = 'This field is required!'

  if (!payload.type) {
    errors.type = REQUIRED_FIELD
  }

  if (payload.type && payload.type !== TICKET_TYPES.MAINTENANCE) {
    if (!payload.project_id) {
      errors.project_id = REQUIRED_FIELD
    }
    if (!payload.category) {
      errors.category = REQUIRED_FIELD
    }
  }

  if (payload.actual_start_date) {
    const startDate = new Date(payload.actual_start_date)

    if (payload.estimated_end_date) {
      const estimatedEndDate = new Date(payload.estimated_end_date)
      if (isBefore(estimatedEndDate, startDate)) {
        errors.estimated_end_date = 'Estimated end date must be after start date!'
      }
    }

    if (payload.actual_end_date) {
      const actualEndDate = new Date(payload.actual_end_date)
      if (isBefore(actualEndDate, startDate)) {
        errors.actual_end_date = 'Actual end date must be after start date!'
      }
    }
  }

  if (payload.estimated_cost && payload.estimated_cost < 0) {
    errors.estimated_cost = 'Estimated cost must be a positive number!'
  }

  if (payload.actual_cost && payload.actual_cost < 0) {
    errors.actual_cost = 'Actual cost must be a positive number!'
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  }
}

export const formatTicketTypeForDisplay = (type: string): string => {
  switch (type) {
    case TICKET_TYPES.TURN:
      return 'Turn'
    case TICKET_TYPES.TURN_OI:
      return 'Turn - OI'
    case TICKET_TYPES.DISPOSITION:
      return 'Dispo'
    case TICKET_TYPES.DISPOSITON_INSPECTION:
      return 'Dispo - Inspection'
    case TICKET_TYPES.REHAB:
      return 'Rehab'
    case TICKET_TYPES.MAINTENANCE:
      return 'Maintenance'
    default:
      return startCase(type)
  }
}
