import React, { useState } from 'react'
import { Button, Center, Spinner } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import QueryString from 'qs'
import { GetConstructionProjectsApiResponse } from '@homevest/types/construction-projects'
import { constructionProjects } from '@homevest/utils'

import axios from 'lib/axios'
import BasicPageLayout from 'components/TailwindUIToolkit/Layouts/BasicPageLayout'
import CreateProjectModal from 'components/Construction/Projects/CreateProjectModal'
import ProjectsGrid from './Grid'

const { ACTIVE_PROJECT_STATUSES, PROJECT_STATUSES } = constructionProjects

const Projects = () => {
  const [isShowingAllProjects, setIsShowingAllProjects] = useState(false)

  const statusesToShow = isShowingAllProjects
    ? Object.values(PROJECT_STATUSES)
    : ACTIVE_PROJECT_STATUSES
  const {
    data,
    isLoading,
    refetch: refetchProjects
  } = useQuery({
    queryKey: ['getConstructionProjects', isShowingAllProjects],
    queryFn: async () => {
      const params = { status: statusesToShow }
      const response = await axios.get<GetConstructionProjectsApiResponse>(
        'admin/construction_projects',
        {
          params,
          paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' })
        }
      )
      return response.data?.data
    }
  })

  const handleToggleAllProjects = () => {
    setIsShowingAllProjects(!isShowingAllProjects)
    refetchProjects()
  }

  if (isLoading) {
    return (
      <Center>
        <Spinner />
      </Center>
    )
  }

  return (
    <BasicPageLayout
      tabTitle='Construction | Projects'
      heading={isShowingAllProjects ? 'Construction Projects (All)' : 'Construction Projects'}
      topRightElement={
        <div className='flex flex-col place-items-end gap-2'>
          {!isShowingAllProjects && <CreateProjectModal refetchProjects={refetchProjects} />}
          <Button onClick={handleToggleAllProjects}>
            {isShowingAllProjects ? 'View Active Projects' : 'View All Projects'}
          </Button>
        </div>
      }
    >
      <div className='p-4'>
        <ProjectsGrid data={data} statusesToShow={statusesToShow} />
      </div>
    </BasicPageLayout>
  )
}

export default Projects
